<template>
  <v-container>
    <row-header action="back" :msg="msgRowHeader"></row-header>

    <v-row dense>
      <v-col>
        <v-form
            ref="form"
            v-model="valid"
        >
          <v-card>
            <bank-form-component :bank_model="bank_model" update="0" />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  :disabled="!valid"
                  color="success"
                  @click="saveData"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <v-overlay
        :absolute="absolute"
        :value="overlay"
    >
      <v-card color="white">
        <v-card-text>
          <overlay-img></overlay-img>
        </v-card-text>
      </v-card>
    </v-overlay>

  </v-container>
</template>

<script>
import RowHeader from "../../../components/content/RowHeader";
import OverlayImg from "../../../components/content/OverlayImg";
import BankFormComponent from '../../../components/page/banks/BankFormComponent';
import { BankData } from '../../../store/services/BankModel';
import {PostRequest} from "../../../store/services/users";

export default {
  components: {
    RowHeader,
    OverlayImg,
    BankFormComponent,
  },
  data:() => ({
    msgRowHeader: {newUser: 'Back', newMsg: 'Create Merchant'},
    valid: true,
    absolute: false,
    overlay: false,
    bank_model: new BankData(),
  }),
  mounted() {
    // this.bank_model.current_balance = 0;
  },
  methods: {
    async saveData() {
      await this.$store.dispatch('req_data')
      this.overlay = this.$store.getters.isLoading;
      try {
        await PostRequest('banks', this.bank_model);
        this.overlay = this.$store.getters.isLoading;
        await this.$store.dispatch('req_success');
        this.overlay = this.$store.getters.isLoading;
        alert('Create data success');
        await this.$router.push({name: 'BankList'});
      } catch (e) {
        await this.$store.dispatch('req_failed');
        this.overlay = this.$store.getters.isLoading;
        alert('Create data failed ' + e.message);
      }
    }
  }
}
</script>

<style scoped>

</style>
